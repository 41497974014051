import axios from 'axios'
import { Message } from 'element-ui'
const idCardUrl = 'http://127.0.0.1:19196/'
let isConnected = false
// 连接读卡器读取身份证信息
export function openDevice() {
  return new Promise((resolve, reject) => {
    // 判断是否已经连接
    if (isConnected) {
      resolve(readCard())
      return
    }
    axios
      .get(idCardUrl + 'OpenDevice')
      .then((res) => {
        // console.log(res)
        if (res.data.resultFlag == 0) {
          Message.success('读卡器连接成功！')
          resolve(readCard())
          isConnected = true
        } else {
          Message.error(res.data.errorMsg)
          reject(res.data.errorMsg)
        }
      })
      .catch((err) => {
        isConnected = false
        Message.error('读卡器连接失败！请先下载插件')
        reject(err)
      })
  })
}
// 身份证读取信息
export function readCard() {
  return new Promise((resolve, reject) => {
    axios
      .get(idCardUrl + 'readcard')
      .then((res) => {
        if (res.data.resultFlag == 0) {
          Message.success('身份证信息读取成功！')
          resolve(res.data)
        } else {
          Message.error(res.data.errorMsg)
          reject(res.data.errorMsg)
        }
      })
      .catch((err) => {
        isConnected = false
        Message.error('身份证读取失败！请刷新后重试')
        reject(err)
      })
  })
}
// 断开连接身份证
export function closeDevice() {
  axios.get(idCardUrl + 'CloseDevice').then((res) => {
    isConnected = false
  })
}

export default {
  openDevice,
  closeDevice,
}
